import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import clsx from 'clsx';
import loadable from '@loadable/component';
import Img from 'gatsby-image';
//styles
import styles from './proptech.module.css';
//components
import Layout from '../components/layout';
import BenefitsTable from 'components/recognition/benefits-table';
import Reviews from './reviews';
import { MyRedDog, Condogenie2, AreaButler, Shineup } from 'components/carousel';
import Technology from 'components/technology';
import BulletedList from 'components/bullets/bulleted-list';
import { UnderlineBullets, ColumnList } from 'components/bullets/titled-bullets';
import Booking from 'components/feedback';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
//assets
import benefits from './benefits.json';
import forwardLooking from './forward-looking-mindset.json';
import collaborationKeys from './collaboration-keys.json';
import managementSolution from './management-solution.json';
import enhances from './enhances.json';
//types
import { Technologies } from 'components/Types';
//other
import { meta } from '../../../metaData';
import identificators from 'components/googleAnalyticsIdentificators';

interface Props {
  location: Location;
}

const technologies: { field: string; list: readonly Technologies[] }[] = [
  {
    field: 'Backend',
    list: ['nodejs', 'ruby', 'python'],
  },
  {
    field: 'Frontend',
    list: ['react', 'vue', 'gatsby', 'reactNativeWide'],
  },
  {
    field: 'Blockchain & AI',
    list: ['python'],
  },
  {
    field: 'Cloud',
    list: ['aws', 'azure', 'google_cloud_platform'],
  },
  {
    field: 'Test automation',
    list: ['selenium', 'zephyr_scale'],
  },
];
const challengesList = [
  {
    title: 'Get rid of technical debt and boost software scalability',
    text: 'Our experts will thoroughly evaluate your technology stack, code quality, and architecture, delivering recommendations and practical solutions to enhance performance, remove scalability bottlenecks, and future-proof your software.',
  },
  {
    title: "Improve your team's productivity to accelerate delivery",
    text: "Through team performance audits, including 360-degree feedback and face-to-face interviews, we'll identify areas for improvement. Our team will fine-tune your processes to boost delivery and help you build a high-performance team.",
  },
];
const Proptech = ({ location }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      offerMob: file(relativePath: { eq: "industries/offer-mob.svg" }) {
        publicURL
      }
      offer: file(relativePath: { eq: "industries/offer.svg" }) {
        publicURL
      }
      aim: file(relativePath: { eq: "industries/aim.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aimMob: file(relativePath: { eq: "industries/aim-mob.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const SwiperSlider = loadable(() => import('components/swiper-slider'));

  const breakpoint = useBreakpoint();

  return (
    <Layout
      title="Proptech Software Development Services"
      subtitle="Hire proptech software developers to audit your solution, solve existing technical problems, and move your project forward."
      linkText="Start your project"
      metaData={{ main: meta.proptech }}
      pathname={location.pathname}
      linkId={identificators.CONTACT_US_HEADER}
    >
      <section className="section">
        <div className="inner">
          <p className={styles.preamble}>
            Fill the talent gap with the right expertise — at Brocoders, we've helped numerous
            companies in the real estate industry develop proptech software solutions. You can get
            our developers on board right away to help you reduce your technical debt and increase
            your team's efficiency.
          </p>
          <BenefitsTable list={benefits} />
          <Link
            id={identificators.START_WITH_A_TECH_AUDIT}
            className={clsx('btn btn_60 btn__red', styles.auditLink)}
            to="/get-in-touch/"
          >
            Start with a tech audit
          </Link>
        </div>
      </section>
      <section className={clsx('section', styles.grey)}>
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.title}>
            We team up with a forward-looking businesses, no matter the growth stage
          </p>
          <UnderlineBullets list={forwardLooking} />
        </div>
      </section>
      <section className="section">
        <div className={clsx('inner-container', styles.containerTopMargin)}>
          <p className={styles.title}>Keys to successful collaboration</p>
          <p className={clsx(styles.lightParagraph, styles.paragraphMargin)}>
            Our services are designed to enhance remote cooperation and long-term relationships.
          </p>
          <BulletedList
            list={collaborationKeys}
            listStyle={styles.values}
            itemStyle={styles.collaborationItem}
          />
        </div>
      </section>
      <section className={clsx('section', styles.grey)}>
        <div className={clsx('inner-container', styles.containerTopMargin)}>
          <p className={clsx(styles.title, styles.challengesMargin)}>
            Our solutions to help overcome your proptech development challenges
          </p>
          <p className={clsx(styles.lightParagraph, styles.challengesMargin)}>
            With years of experience in running development teams and tackling various tech
            challenges, Brocoders is well-equipped to accelerate your project delivery and resolve
            any software-related issues.
          </p>
          <ColumnList list={challengesList} />
          <Link
            id={identificators.START_WITH_A_TECH_AUDIT}
            className={clsx('btn btn_60 btn__red', styles.auditLink)}
            to="/get-in-touch/"
          >
            Start with a tech audit
          </Link>
        </div>
      </section>
      <section className="section">
        <div className={clsx('inner-container', styles.responseContainer)}>
          <p className={styles.title}>Our recent work</p>
          <p className={styles.lightParagraph}>
            Learn about our recent proptech software development projects.
          </p>
        </div>
      </section>
      <section className={styles.carousel}>
        <SwiperSlider delay={7000}>
          <MyRedDog />
          <Condogenie2 />
          <AreaButler />
          <Shineup />
        </SwiperSlider>
      </section>
      <section className={clsx('section', styles.grey)}>
        <div className={clsx('inner-container', styles.containerTopMargin)}>
          <p className={styles.title}>
            Don't gamble with your project. Hire a team with proven expertise in building proptech
            solutions
          </p>
          <p className={clsx(styles.lightParagraph, styles.challengesMargin)}>
            With hands-on experience in developing custom property management systems, our experts
            can seamlessly integrate into your project to enhance and speed up the delivery.
          </p>
          <BulletedList list={enhances} listStyle={styles.bulletsListContainer} />
          <Link
            id={identificators.START_WITH_A_TECH_AUDIT}
            className={clsx('btn btn_60 btn__red', styles.auditLink)}
            to="/get-in-touch/"
          >
            Start with a tech audit
          </Link>
        </div>
      </section>
      <section className="section">
        <div className={clsx('inner-container', styles.containerTopMargin)}>
          <p className={styles.title}>What technologies are required for your project?</p>
          <p className={clsx(styles.lightParagraph, styles.technologyMargin)}>
            See our core tech stack below.
          </p>
          {breakpoint.m ? (
            <SwiperSlider
              navigationEnabled={false}
              autoPlay={true}
              slideClassName={styles.slide}
              paginationColor="#14181A"
              paginationInactiveColor="#656c7b"
              activeBulletClass={styles.activeBullet}
            >
              {technologies.map(({ field, list }) => (
                <div>
                  <p className={styles.technologyTitle}>{field}</p>
                  <Technology list={list} listStyle={styles.technologyMobileList} />
                </div>
              ))}
            </SwiperSlider>
          ) : (
            <ul className={styles.technologyList}>
              {technologies.map(({ field, list }) => (
                <li key={field}>
                  <p className={styles.technologyTitle}>{field}</p>
                  <Technology list={list} listStyle={styles.technologySubList} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
      <section className={clsx('section', styles.containerOffer)}>
        <div className="inner">
          <picture>
            <source
              srcSet={data.offerMob.publicURL}
              media="(max-width: 768px)"
              type="image/svg+xml"
            />
            <source srcSet={data.offer.publicURL} media="(min-width: 769px)" type="image/svg+xml" />
            <img src={data.offer.publicURL} className={styles.offerIcon} alt="offer picture" />
          </picture>
          <div className={styles.offerBox}>
            <p className={styles.offerText}>Contact us to learn about our extensive tech stack.</p>
            <Link
              id={identificators.CONTACT_US}
              className={clsx('btn btn_60 btn__black', styles.wideLink, styles.linkOffer)}
              to="/get-in-touch/"
            >
              Contact us
            </Link>
          </div>
        </div>
      </section>
      <section className="section">
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.title}>What our clients say about us</p>
        </div>
      </section>
      <section className="section">
        <Reviews />
        <div className={clsx('inner-container', styles.containerTopMargin)}>
          <div className={styles.percentageBox}>
            <p className={styles.point}>70%</p>
            <p className={clsx(styles.title, styles.candidates)}>
              of candidates, proposed by outsourcing agencies, fail to meet client expectations
            </p>
          </div>
          <div className={styles.candidatesBox}>
            {breakpoint.s ? (
              <Img fluid={data.aimMob.childImageSharp.fluid} className={styles.aimImg} alt="" />
            ) : (
              <Img fluid={data.aim.childImageSharp.fluid} className={styles.aimImg} alt="" />
            )}
            <p className={clsx(styles.title, styles.candidates)}>This is because 90%</p>
            <p className={styles.challengesText}>
              of the candidates left on the "bench" are assigned to projects regardless of whether
              they truly match the client's requirements. We value your time so we start the project
              with a tech audit to make sure you get the right talent. Don’t spend days dealing with
              interviews. Let us do a tech audit of your solution and pick the top 3 candidates for
              you.
            </p>
          </div>
          <Link
            id={identificators.START_WITH_A_TECH_AUDIT}
            className={clsx('btn btn_60 btn__red', styles.auditLink)}
            to="/get-in-touch/"
          >
            Start with a tech audit
          </Link>
        </div>
      </section>
      <section className={clsx('section', styles.violet)}>
        <div className={clsx('inner-container', styles.violetContainer)}>
          <p className={clsx(styles.title, styles.managementTitle)}>
            Hire an expert team to build a property management solution
          </p>
          <BulletedList
            list={managementSolution}
            listStyle={styles.managementList}
            itemStyle={styles.managementItem}
          />
        </div>
      </section>
      <Booking />
    </Layout>
  );
};

export default Proptech;
