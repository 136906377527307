import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';
//components
import Blockquote from '../../services/components/blockquote';

const ReviewSlider = loadable(() => import('components/swiper-slider/review-slider'));

const Reviews = () => {
  const data = useStaticQuery(graphql`
    query {
      alexanderTimper: file(relativePath: { eq: "alexander-timper.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      areaButler: file(relativePath: { eq: "area-butler.svg" }) {
        publicURL
      }
      KarolinaKwiecinskaPhoto: file(relativePath: { eq: "Karolina-Kwiecinska-Photo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      geniana: file(relativePath: { eq: "case-logos/geniana.svg" }) {
        publicURL
      }
      ralphTroiano: file(relativePath: { eq: "ralphTroiano.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cia: file(relativePath: { eq: "case-logos/cia.svg" }) {
        publicURL
      }
      rafalDyrda: file(relativePath: { eq: "rafal-dyrda.png" }) {
        childImageSharp {
          fluid(maxWidth: 430, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      condogenie: file(relativePath: { eq: "case-logos/condogenie.svg" }) {
        publicURL
      }
      timDeJonge: file(relativePath: { eq: "tim-de-jonge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backbone: file(relativePath: { eq: "case-logos/backbone.svg" }) {
        publicURL
      }
      bukenya: file(relativePath: { eq: "anuel-bukenya.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beyondGreen: file(relativePath: { eq: "case-logos/beyond-green.svg" }) {
        publicURL
      }
      gregChristian: file(relativePath: { eq: "GregChristian.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fahimSaleh: file(relativePath: { eq: "fahim-saleh.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 645) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gokada: file(relativePath: { eq: "case-logos/gokada.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <section className="section">
      <ReviewSlider>
        <Blockquote
          text="“After working together for 4 months Brocoders is fully part of our team. The service delivered is of very high quality. The execution is quick and includes a very close communication. In case of bugs the Brocoders team is super responsive and takes actions also late at night.”"
          img={data.alexanderTimper.childImageSharp.fluid}
          isLogo={true}
          logoImg={data.areaButler.publicURL}
          isRating
          author={
            <React.Fragment>
              <strong>Alexander Timper </strong> <br />
              CEO &amp; Founder,{' '}
              <a
                href="https://area-butler.de/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                AreaButler
              </a>
            </React.Fragment>
          }
        />
        <Blockquote
          text={
            '"Software development is complex work that requires considering many points at the same time. The hardest thing, I guess, is to have the same vision of the product, when you are located in different countries. But I think that the team did their best at this point. They set new calls, drew new sketches and produced new specifications to match my requirements. \n They listened to me and used various tools to get on the same page with me."'
          }
          img={data.KarolinaKwiecinskaPhoto.childImageSharp.fluid}
          isRating
          isLogo={true}
          logoImg={data.geniana.publicURL}
          author={
            <React.Fragment>
              <b>Karolina Kwiecinska</b>
              <br />
              CEO,{' '}
              <a
                href="https://geriana.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Geriana
              </a>
            </React.Fragment>
          }
        />
        <Blockquote
          img={data.ralphTroiano.childImageSharp.fluid}
          isRating
          isLogo={true}
          logoImg={data.cia.publicURL}
          text={
            '“I am very excited and thankful for the accomplished work. It was great to work with a so professional and well-organized team. All tasks were delivered on time and were well-tested by QA specialists and our users. Reddog backend isn’t ready for all functionality we need, so after finishing it, we return to work on this project with Brocoders and release the full version to production.”'
          }
          author={
            <React.Fragment>
              <strong>Ralph Troiano</strong> <br />
              President &amp; CEO,{' '}
              <a href="https://www.ciaservices.com" target="_blank" className="link">
                C.I.A.Services
              </a>
            </React.Fragment>
          }
        />
        <Blockquote
          img={data.rafalDyrda.childImageSharp.fluid}
          isRating
          isLogo={true}
          logoImg={data.condogenie.publicURL}
          text={`"I had very great communication with the team. Upon launching the project, we were able to increase client satisfaction and provide our clients what they were asking for, improve their workflow as well as make the software more powerful and easier to use than the previous software that was developed."`}
          author={
            <React.Fragment>
              <strong>Rafal Dyrda</strong> <br />
              CEO and Founder,{' '}
              <a href="https://www.condogenie.com" target="__blank" className="link">
                CondoGenie
              </a>
            </React.Fragment>
          }
        />
        <Blockquote
          text="“It seemed clear that Backbone could profit from an application that supports a large number of the processes, so everyone could find and save their information in the same place. The importance of the applications like these not only lies in advantages in regard to scalability, but also in more insights, unity and efficiency, and therefore a lower workload.“"
          img={data.timDeJonge.childImageSharp.fluid}
          isRating
          isLogo={true}
          logoImg={data.backbone.publicURL}
          author={
            <React.Fragment>
              <strong>Tim de Jonge</strong> <br />
              Project Coordinator,{' '}
              <a className="link" href="https://www.backbone-international.com/" target="__blank">
                Backbone International
              </a>
            </React.Fragment>
          }
        />
        <Blockquote
          text={`"The platform has received positive feedback. For such an extensive, complicated project, Brocoders has taken scope changes in stride and has effectively implemented change requests. They're organized and communicative, even corresponding during after-hours. They're a flexible and patient partner."`}
          img={data.bukenya.childImageSharp.fluid}
          isRating
          //   innerClassName={styles.fineBq}
          author={
            <React.Fragment>
              <strong>Emmanuel Bukenya</strong> <br />
              CEO and Fine, Renewable Energy Startup
            </React.Fragment>
          }
        />
        <Blockquote
          img={data.gregChristian.childImageSharp.fluid}
          text='"When we discussed our ideas, Brocoders reminded us to keep it simple. Usually, development companies want to work on complex projects. Brocoders’ leadership team told us to keep the product simple, which made it better. They also never guessed what we needed. All the other agencies that we interviewed took big chances and told us what they thought we needed. They’re a team of authentic listeners."'
          isRating
          isLogo={true}
          logoImg={data.beyondGreen.publicURL}
          author={
            <React.Fragment>
              <strong>Greg Christian</strong>
              <br />
              CEO,{' '}
              <a href="https://beyondgreenpartners.com/" target="__blank" className="link">
                Beyond Green
              </a>
            </React.Fragment>
          }
        />
        <Blockquote
          img={data.fahimSaleh.childImageSharp.fluid}
          text="“Brocoders is a very productive and effective team. We were happy to meet them in Lagos, where they came to meet Gokada team and test application in a real-time.”"
          isRating
          isLogo={true}
          logoImg={data.gokada.publicURL}
          author={
            <React.Fragment>
              <b> Fahim Saleh</b> <br />
              Founder and CEO,{' '}
              <a href="https://www.gokada.ng/" target="__blank" className="link">
                Gokada
              </a>
            </React.Fragment>
          }
        />
      </ReviewSlider>
    </section>
  );
};

export default Reviews;
