import React from 'react';
import clsx from 'clsx';
import styles from './benefits-table.module.css';

interface IBenefitsTable {
  list: {
    point: string;
    description: string;
  }[];
  containerClass?: string;
}

export default function BenefitsTable({ list, containerClass }: IBenefitsTable) {
  return (
    <div className={clsx(styles.container, containerClass)}>
      {list.map(({ point, description }) => (
        <dl className={styles.cell} key={point}>
          <dt className={styles.point} dangerouslySetInnerHTML={{ __html: point }}></dt>
          <dd className={styles.description}>{description}</dd>
        </dl>
      ))}
    </div>
  );
}
